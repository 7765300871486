<template>
	<div class="inbox-item" :class="{ active }">
		<div class="inbox-item-section">
			<router-link
				:to="{ name: 'app.paging.show', params: { sid: page.sid } }"
				class="inbox-item-title"
			>
				<div
					v-if="isComplete"
					class="complete-call"
					role="button"
					data-tooltip="Complete"
					data-position="right"
				>
					<font-awesome-icon fixed-width :icon="['fas', 'check']" />
				</div>
				<div
					v-else
					class="new-call"
					role="button"
					data-tooltip="New"
					data-position="right"
				>
					<font-awesome-icon fixed-width :icon="['fas', 'circle']" />
				</div>
				{{ page.patient_name }}
			</router-link>
			<h5
				v-if="page.patient_name || page.patient_dob"
				class="inbox-item-subtitle"
			>
				<div class="inbox-dob-container">
					<span v-if="page.patient_dob" class="call-dob">
						{{ page.patient_dob }}
					</span>

					<span
						v-if="page.patient_name && page.patient_dob"
						class="call-spacer"
					>
						&bull;
					</span>
				</div>

				<span v-if="page.patient_name" class="call-phone">
					{{ page.callback_number | newFormatPhoneNumber }}
				</span>
			</h5>
		</div>
		<div class="inbox-item-timestamp">
			{{ page.created_at | toTimestamp }}
		</div>
		<div class="inbox-item-section w-100">
			<div class="inbox-item-message">
				{{ page.patient_message }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the page is the current active page being displayed.
		 *
		 * @return {Boolean}
		 */
		active() {
			return this.page.sid === this.$route.params.sid
		},

		/**
		 * Determine if the current page is complete.
		 *
		 * @return {Boolean}
		 */
		isComplete() {
			return !!this.page.completed_at
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'PageCard',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The page to be displayed.
		 *
		 * @type {Object}
		 */
		page: {
			type: Object,
			required: true,
		},
	},
}
</script>
